import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'

const DEFAULT_TITLE = '블루닷'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "posts" */ '@/views/HomeView.vue'),
    meta: {
      title: '블루닷 - 콘텐츠 SMB를 위한 AI 검색최적화 CMS'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    meta: {
      title: 'About | ' + DEFAULT_TITLE
    }
  },
  {
    path: '/posts',
    name: 'posts',
    component: () => import(/* webpackChunkName: "posts" */ '@/views/PostView.vue'),
    meta: {
      title: 'Contents | ' + DEFAULT_TITLE
    }
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '@/views/PartnerView.vue'),
    meta: {
      title: 'Partner | ' + DEFAULT_TITLE
    }
  },
  {
    path: '/bluedot',
    name: 'bluedot',
    component: () => import(/* webpackChunkName: "bluedot" */ '@/views/BluedotView.vue'),
    meta: {
      title: 'Bluedot | ' + DEFAULT_TITLE
    }
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/ApplyView.vue'),
    meta: {
      title: 'Apply | ' + DEFAULT_TITLE,
      header: 'alliance',
      footer: false
    }
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/DemoView.vue'),
    meta: {
      title: 'Demo | ' + DEFAULT_TITLE,
      header: 'alliance',
      footer: false
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/PricingView.vue'),
    meta: {
      title: 'Pricing | ' + DEFAULT_TITLE
      // header: 'alliance',
      // footer: false
    }
  },
  {
    path: '/signin/',
    name: 'signin',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/SigninView.vue'),
    meta: {
      title: 'Sign in | ' + DEFAULT_TITLE
    }
  },
  {
    path: '/product/:target',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/ProductView.vue'),
    meta: {
      title: 'Product | ' + DEFAULT_TITLE,
      footer: false,
      isHeaderSticky: false
    }
  },
  {
    path: '/owel/',
    name: 'openai',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/OpenAiView.vue'),
    meta: {
      title: 'Owel | ' + DEFAULT_TITLE,
      header: 'alter',
      footer: false
    }
  },
  {
    path: '/owel/grammar/',
    name: 'owel-grammar',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/OwelGrammarView.vue'),
    meta: {
      title: 'Owel Grammar | ' + DEFAULT_TITLE,
      header: 'alter',
      footer: false
    }
  },
  {
    path: '/owel/seo/',
    name: 'owel-seo',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/OwelSeoView.vue'),
    meta: {
      title: 'Owel SEO | ' + DEFAULT_TITLE,
      header: 'alter',
      footer: false
    }
  },
  {
    path: '/owel/news/',
    name: 'owel-news',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/OwelNewsView.vue'),
    meta: {
      title: 'Owel News | ' + DEFAULT_TITLE,
      header: 'alter',
      footer: false
    }
  },
  {
    path: '/owel/academy/',
    name: 'owel-academy',
    component: () => import(/* webpackChunkName: "apply" */ '@/views/OwelAcademyView.vue'),
    meta: {
      title: 'Owel Academy | ' + DEFAULT_TITLE,
      header: 'alter',
      footer: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
